/**
 * @desc Client Side Router
 * 
 *
 */
define('router',[
	'jquery',
	'underscore',
	'backbone',
	'utils'
	
],	function($, _, Backbone, Utils){
	
		var AppRouter = Backbone.Router.extend({
			
			routes: {
				'': 'app'
			}
			
		});
		
		var initialize = function(){

			// check browser
			Utils.checkBrowser();
		
			router = new AppRouter;

			require(['views/app'], function (AppView) {
				var appView = new AppView();
			});

			/**
			 * Home/App View
			 */
			router.on('route:app', function(){

			});
			
			// start history
			Backbone.history.start({pushState: true});
		
		};
		
	return {
		
		initialize : initialize
		
	};
	
});


