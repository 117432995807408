/**
 * @desc Home View
 * 
 *
 */
define('views/app',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'bootstrap',
	'waypoints',
	'text!templates/modal-structure.html',

],	function($, _, Backbone, Utils, Bootstrap, Waypoints, StructureModalTemplate){
	
	return Backbone.View.extend({
		
		// setup DOM Elements
		el : $('body'),
		structureModalTemplte: _.template(StructureModalTemplate),
		$structureModal: $('#modal-structure'),
		
		// bind Events
		events: {
			'click .trigger-show-structure' : 'showStructureModal'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){
			console.log('App View');
			
			this.district = window.district;
			
			this.subheaderNavWaypoint();
			this.subheaderWaypoint();
			
		},
		
		/**
		 * render()
		 * @desc Render the view
		 * 
		 * 
		 */			
		render: function(){
			
			// No render
			
		},


		showStructureModal: function( event ){
			
			event.preventDefault();
			
			var $clicked = $(event.currentTarget);
			
			var structure = JSON.parse($clicked.attr('data-structure'));
			
			console.log(structure);
			
			var $modalBody = this.$structureModal.find('.modal-body');

			$modalBody.empty();

			$modalBody.append(this.structureModalTemplte({
				structure: structure,
				district: this.district
			}));
			
			this.$structureModal.modal('show');
			
			
		},

		/**
		 *
		 * navigationWaypoint()
		 *
		 */
		subheaderWaypoint: function(){
			var $subheader = $('#subheader');
			$subheader.waypoint( function( direction ) {
				console.log('waypoint');
				if( direction === 'down' ) {

					$subheader.addClass('sub-header--fixed');

				}
				if( direction === 'up' ) {
					if($subheader.hasClass('sub-header--fixed')){
						$subheader.removeClass('sub-header--fixed');
					}

				}
			}, { offset: '67' } );
		},

		/**
		 *
		 * navigationWaypoint()
		 *
		 */
		subheaderNavWaypoint: function(){
			var $navWaypoints = $( '.trigger-waypoint-subheader' );
			$navWaypoints.waypoint( function( direction ) {
				if( direction === 'down' ) {
					var waypointId = $(this.element).attr('data-waypoint-id');
					$('#subheader .nav-item').removeClass('active');
					if( !waypointId ) {
						return;
					}
					$('#subheader .nav-item[data-waypoint-id="' + waypointId + '"]').addClass('active');
				}
				if( direction === 'up' ) {
					var waypointId = $(this.element).attr('data-waypoint-id');
					$('#subheader .nav-item').removeClass('active');
					if( !waypointId ) {
						return;
					}
					$('#subheader .nav-item[data-waypoint-id="' + waypointId + '"]').addClass('active')
				}
			}, { offset: '100' } );
		},
				
	});
});
